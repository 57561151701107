/* eslint-disable */
import React, { Component, useRef } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import Link from "next/link";

import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import Hidden from "@mui/material/Hidden";

// @mui/icons-material
import Apps from "@mui/icons-material/Apps";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import ViewDay from "@mui/icons-material/ViewDay";
import Dns from "@mui/icons-material/Dns";
import Build from "@mui/icons-material/Build";
import ListIcon from "@mui/icons-material/List";
import People from "@mui/icons-material/People";
import Assignment from "@mui/icons-material/Assignment";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import Chat from "@mui/icons-material/Chat";
import Call from "@mui/icons-material/Call";
import ViewCarousel from "@mui/icons-material/ViewCarousel";
import AccountBalance from "@mui/icons-material/AccountBalance";
import ArtTrack from "@mui/icons-material/ArtTrack";
import ViewQuilt from "@mui/icons-material/ViewQuilt";
import LocationOn from "@mui/icons-material/LocationOn";
import Fingerprint from "@mui/icons-material/Fingerprint";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Store from "@mui/icons-material/Store";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Layers from "@mui/icons-material/Layers";
import ShoppingBasket from "@mui/icons-material/ShoppingBasket";
import LineStyle from "@mui/icons-material/LineStyle";
import Error from "@mui/icons-material/Error";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "styles/jss/nextjs-material-kit-pro/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const inputRef = React.createRef(null);

export default function HeaderLinks({
  dropdownHoverColor = "primary"
}) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  
  var onClickSections = {};

  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="About"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          // buttonIcon={Apps}
          dropdownList={[
            <Link className={classes.dropdownLink} href="/about/about-us">
              <ListIcon className={classes.dropdownIcons} /> About Us
            </Link>,
            <Link className={classes.dropdownLink} href="/team">
              <People className={classes.dropdownIcons} /> Our Team
            </Link>,
            <Link className={classes.dropdownLink} href="/about/photo-gallery">
              <ArtTrack className={classes.dropdownIcons} />
              Photo Gallery
            </Link>,
            <Link
              className={classes.dropdownLink}
              href="/resources/setting-up-google-analytics"
            >
              <Build className={classes.dropdownIcons} />
              Resources - Google Analytics
            </Link>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Hidden lgDown>
          <Button
            href="/services/"
            //target="_blank"
            color={"white"}
            className={classes.navButton}
            round
            simple
          >
            Services
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Button
            href="/services/"
            //target="_blank"
            color={"github"}
            className={classes.navButton}
            round
            simple
          >
            Services
          </Button>
        </Hidden>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Hidden lgDown>
          <Button
            href="/our-work/"
            color={"white"}
            className={classes.navButton}
            round
            simple
          >
            Our Work
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Button
            href="/our-work/"
            color={"github"}
            className={classes.navButton}
            round
            simple
          >
            Our Work
          </Button>
        </Hidden>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Hidden lgDown>
          <Button
            href="/blog"
            color={"white"}
            className={classes.navButton}
            round
            simple
          >
            Blog
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Button
            href="/blog"
            color={"github"}
            className={classes.navButton}
            round
            simple
          >
            Blog
          </Button>
        </Hidden>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Hidden lgDown>
          <Button
            href="/contact-us"
            color={"white"}
            className={classes.navButton}
            round
            simple
          >
            Contact Us
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Button
            href="/contact-us"
            color={"github"}
            className={classes.navButton}
            round
            simple
          >
            Contact Us
          </Button>
        </Hidden>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Hidden mdDown>
          <Button
            color="transparent"
            href="https://twitter.com/wearetribalism"
            target="_blank"
            className={classes.navLink + " " + classes.navLinkJustIcon}
          >
            <i className={"fab fa-twitter"} />
          </Button>
        </Hidden>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Hidden mdDown>
          <Button
            color="transparent"
            href="https://www.linkedin.com/company/tribalism"
            target="_blank"
            className={classes.navLink + " " + classes.navLinkJustIcon}
          >
            <i className={"fab fa-linkedin"} />
          </Button>
        </Hidden>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Hidden mdDown>
          <Button
            color="transparent"
            href="https://www.instagram.com/wearetribalism/"
            target="_blank"
            className={classes.navLink + " " + classes.navLinkJustIcon}
          >
            <i className={"fab fa-instagram"} />
          </Button>
        </Hidden>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Hidden mdDown>
          <Button
            color="transparent"
            href="https://www.facebook.com/wearetribalism/"
            target="_blank"
            className={classes.navLink + " " + classes.navLinkJustIcon}
          >
            <i className={"fab fa-facebook"} />
          </Button>
        </Hidden>
      </ListItem>
    </List>
  );
}

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
